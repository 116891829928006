import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const getAnalogsListBySku = asyncActionsCreator(
  constants.GET_ANALOGS_LIST
);

export const deleteSuggestion = asyncActionsCreator(
  constants.DELETE_SUGGESTION
);

export const submitSuggestion = asyncActionsCreator(
  constants.SUBMIT_SUGGESTION
);
