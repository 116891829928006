import {
  SortableItemProps,
  SortableList,
} from '@thaddeusjiang/react-sortable-list';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './ModeratePage.styles';
import { StyledAnalogsPageContainer } from './ModeratePage.styles';
import * as actions from './actions';
import { selectors } from './reducer';
import { AnalogDataFormated } from './types';

const ModerationPage = () => {
  const dispatch = useDispatch();
  const analogsData = useSelector(selectors.analogsData);
  const { t } = useTranslation();
  const [searchBySkuValue, setSearchBySkuValue] =
    useState<string>('reviews_moderate');
  const [dataType, setDataType] = useState<string>('analog');
  const [isDraggingActive, setIsDraggingActive] = useState(true);
  const [items, setItems] = useState<
    AnalogDataFormated[] | SortableItemProps[]
  >(analogsData as AnalogDataFormated[]);

  useEffect(() => {
    setDataType(dataType);
    dispatch(
      actions.getAnalogsListBySku.request({
        key: searchBySkuValue,
        type: dataType,
      })
    );
  }, []);

  useEffect(() => {
    if (analogsData) {
      setItems(analogsData);
    }
  }, [analogsData]);

  const onDeleteSuggestion = (id: number | string) => {
    dispatch(
      actions.deleteSuggestion.request({
        id,
        postEffect: () => {
          dispatch(
            actions.getAnalogsListBySku.request({
              key: searchBySkuValue,
              type: dataType,
            })
          );
        },
      })
    );
  };

  const onSybmitSuggestion = (id: number | string) => {
    const itemsArr = items as AnalogDataFormated[];
    const item = itemsArr.find((item) => item.id === id);
    const { value } = item!;
    const { sku } = JSON.parse(item!.value);

    dispatch(
      actions.submitSuggestion.request({
        sku,
        value,
        postEffect: () => {
          onDeleteSuggestion(id);
        },
      })
    );
  };

  const orderForm = ({
    handleSubmit,
  }: {
    values: AnalogDataFormated;
    handleSubmit: () => void;
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <Styled.TableBodyContainer>
          <SortableList
            items={items as SortableItemProps[]}
            disabled={isDraggingActive}
            setItems={
              setItems as React.Dispatch<
                React.SetStateAction<SortableItemProps[]>
              >
            }
            // eslint-disable-next-line react/no-unstable-nested-components
            itemRender={({ item }) => {
              let parsedItem;
              try {
                parsedItem = JSON.parse(item.value);
              } catch (error) {
                return (
                  <div className="error-item">
                    <div className="error-title">
                      Ошибка данных. Отвяжите отзыв.
                    </div>
                    <div
                      className="assembly-btn cancel-btn"
                      onClick={() => onDeleteSuggestion(item.id)}
                    >
                      {t('ModerationPage.ACTIONS.CANCEL')}
                    </div>
                  </div>
                );
              }

              return (
                <div
                  className={`product-wrapper ${
                    !isDraggingActive ? 'product-wrapper--dragging' : ''
                  }  `}
                  style={{ width: '100%', display: 'flex' }}
                  key={item.id}
                >
                  <div className="table-cell table-cell-lg name-container">
                    <p className="name-title">{parsedItem.productName}</p>
                  </div>
                  <div className="table-cell table-cell-xsm">
                    {parsedItem.username === ''
                      ? 'Аноним'
                      : parsedItem.username}
                  </div>
                  <div className="table-cell table-cell-comment">
                    {parsedItem.comment_text === ''
                      ? 'Пустой комментарий'
                      : parsedItem.comment_text}
                  </div>
                  <div className="table-cell reting-container">
                    <div>{parsedItem.ratingValue}</div>
                  </div>
                  <div className="table-cell table-cell-sm">
                    <div
                      className="assembly-btn"
                      onClick={() => onSybmitSuggestion(item.id)}
                    >
                      {t('ModerationPage.ACTIONS.SAVE')}
                    </div>
                    <div
                      className="assembly-btn cancel-btn"
                      onClick={() => onDeleteSuggestion(item.id)}
                    >
                      {t('ModerationPage.ACTIONS.CANCEL')}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </Styled.TableBodyContainer>
      </form>
    );
  };

  const tableHeader = (
    <Styled.AnalogsPageTableHeaderContainer>
      <div className="header-row header-row-lg">
        {t('ModerationPage.TABLE.HEADER.NAME')}
      </div>
      <div className="header-row header-row-xsm">
        {t('ModerationPage.TABLE.HEADER.CUSTOMER')}
      </div>
      <div className="header-row ">
        {t('ModerationPage.TABLE.HEADER.COMMENT')}
      </div>
      <div className="header-row header-rating-container">
        {t('ModerationPage.TABLE.HEADER.RATING')}
      </div>
      <div className="header-row header-row-sm">
        {t('ModerationPage.TABLE.HEADER.ACTIONS')}
      </div>
    </Styled.AnalogsPageTableHeaderContainer>
  );

  return (
    <StyledAnalogsPageContainer>
      {items && (
        <>
          {tableHeader}
          <Formik initialValues={items} enableReinitialize onSubmit={() => {}}>
            {orderForm}
          </Formik>
        </>
      )}
    </StyledAnalogsPageContainer>
  );
};

export default ModerationPage;
