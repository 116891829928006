import { combineReducers } from 'redux';
import { loginReducer } from './containers/LoginPage';
import { ordersPageReducer } from './containers/OrdersPage';
import { initComponentReducer } from './containers/InitComponent';
import { productsPageReducer } from './containers/ProductsPage';
import { editPageReducer } from './containers/EditPage';
import { orderPageReducer } from './containers/OrderPage';
import { historyPageReducer } from './containers/OrderHistoryPage';
import { adminPageReducer } from './containers/SourcesPage';
import { sourcePageReducer } from './containers/SourcePage';
import { networkPageReducer } from './containers/NetworkPage';
import { operatorsPageReducer } from './containers/OperatorsPharmacistPage';
import { promoCodePageReducer } from './containers/PromoCodePage';
import { reportPageReducer } from './containers/ReportPage';
import { activeUsersReducer } from '../src/components/ActivePharmacistBlock';
import { webStockPageReducer } from './containers/WebStockPage';
import { analogsPageReducer } from './containers/AnalogsPage';
import { settingsPageReducer } from './containers/SettingsPage/index';
import { moderationPageReducer } from './containers/ModerationPage';

export default combineReducers({
  loginReducer,
  ordersPageReducer,
  initComponentReducer,
  productsPageReducer,
  editPageReducer,
  orderPageReducer,
  historyPageReducer,
  adminPageReducer,
  sourcePageReducer,
  networkPageReducer,
  operatorsPageReducer,
  reportPageReducer,
  activeUsersReducer,
  promoCodePageReducer,
  webStockPageReducer,
  analogsPageReducer,
  moderationPageReducer,
  settingsPageReducer,
});
