import { all } from 'redux-saga/effects';
import { loginPageWatcherSaga } from './containers/LoginPage/saga';
import { ordersPageWatcherSaga } from './containers/OrdersPage/saga';
import { productsPageWatcherSaga } from './containers/ProductsPage/saga';
import { editPageWatcherSaga } from './containers/EditPage/saga';
import { orderPageWatcherSaga } from './containers/OrderPage/saga';
import { orderHistoryPageWatcherSaga } from './containers/OrderHistoryPage/saga';
import { sourcesPageWatcherSaga } from './containers/SourcesPage/saga';
import { sourcePageWatcherSaga } from './containers/SourcePage/saga';
import { networkPageWatcherSaga } from './containers/NetworkPage/saga';
import { operatorPageWatcherSaga } from './containers/OperatorsPharmacistPage/saga';
import { reportPageWatcherSaga } from './containers/ReportPage/saga';
import { templatesPageWatcherSaga } from './containers/PromoCodePage/saga';
import { activeUsersWatcherSaga } from '../src/components/ActivePharmacistBlock/saga';
import { webStockPageWatcherSaga } from './containers/WebStockPage/saga';
import { analogsPageWatcherSaga } from './containers/AnalogsPage/saga';
import { settingPageWatcherSaga } from './containers/SettingsPage/saga';
import { moderatePageWatcherSaga } from './containers/ModerationPage/saga';

export default function* rootSaga() {
  yield all([
    loginPageWatcherSaga(),
    ordersPageWatcherSaga(),
    productsPageWatcherSaga(),
    editPageWatcherSaga(),
    orderPageWatcherSaga(),
    orderHistoryPageWatcherSaga(),
    sourcesPageWatcherSaga(),
    sourcePageWatcherSaga(),
    networkPageWatcherSaga(),
    operatorPageWatcherSaga(),
    reportPageWatcherSaga(),
    activeUsersWatcherSaga(),
    webStockPageWatcherSaga(),
    analogsPageWatcherSaga(),
    moderatePageWatcherSaga(),
    templatesPageWatcherSaga(),
    settingPageWatcherSaga(),
  ]);
}
